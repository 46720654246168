<template>
  <ClubTemplate :clubId="clubId">
    <div class="container">
        <div class="page-header">
        <div class="page-header__ell">
          <h1 class="page-title">Добавить новость</h1>
        </div>
        </div>
        <ClubNewsAddForm ref="clubNewsAddForm" />
        <form class="form">
          <div class="btn-gp">
            <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
            <router-link :to="{name: this.$routeName.CLUB_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
          </div>
        </form>
    </div>
  </ClubTemplate>
</template>

<script>
import ClubTemplate from './_component/ClubTemplate.vue';
import ClubNewsAddForm from "@component/Form/ClubNewsAddForm";

export default {
  components: {
    ClubTemplate,
    ClubNewsAddForm
  },
  props: {
    clubId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
    
    };
  },
  methods: {
    async save() {
      let formData = this.$refs.clubNewsAddForm.formSubmitGetData();
      if(!formData) { return; }
      
      let sendData = Object.assign({}, formData);
      // delete sendData.created_at;
      // sendData.published_at = "2021-04-19T11:09:38.382Z";
      //sendData.mobile = sendData.mobile.replace(/-|\s+/g,"");
      RequestManager.Club.addClubNews({ clubId: this.clubId, news: sendData }).then( (res) => {
        this.$toasts.push({
          message: 'Новость успешно добавлена',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.CLUB_NEWS_LIST });
      });
    }
  }
};
</script>
